@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-menu;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;

@include motion-ui-transitions;
@include motion-ui-animations;


// Spacers

$spacer: rem-calc(2);
$spacers: 40;

@for $i from 1 through $spacers {
  .spacer-#{$i} {
    height: $spacer * $i;
  }
}

/* Colours */
$blockcolor:#E1E2E4;

@font-face {
    font-family: 'BandaRegularRegular';
    src: url('../webfonts/Banda_regular-webfont.eot');
    src: url('../webfonts/Banda_regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/Banda_regular-webfont.woff') format('woff'),
         url('../webfonts/Banda_regular-webfont.ttf') format('truetype'),
         url('../webfonts/Banda_regular-webfont.svg#BandaRegularRegular') format('svg');
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'BandaRegularRegular';
	font-weight: normal;
    font-style: normal;
	color: $primary-color;
}

h1{font-size:30px;}
h2{font-size:24px;}
h3{font-size:20px;}
h4{font-size:18px;}

a {transition: color 0.3s ease-out 0s;}

/* Header */

header{background-color: lighten($blockcolor, 5%); }

/* Navigation*/
.navigation{background-color:$primary-color; border-top: 1px solid darken($primary-color, 20%); border-bottom:1px solid darken($primary-color, 20%);}



$topbar-hover-color: darken($primary-color, 20%);

.main-topbar {
  background: $primary-color;
  font-size: rem-calc(13);
  font-weight: bold;
  z-index: 2;
  position: relative;

  @include clearfix;

  ul:first-child { float: right; }
  ul:last-child  { float: left; }

  
  .menu a {
    height: 45px;
    line-height: 45px !important;
    color: $light-gray;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    &:hover:not(.button),
    &:focus {
      background: darken($primary-color, 20%);
    }

    &.button {
      border-radius: 0;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
  }

  .is-active a {
    background: darken($primary-color, 20%);
  }
  
   .menu:last-child {
    border-left: 1px solid darken($primary-color, 20%);
  }

  .menu {
    li:not(:last-child) {
      border-right: 1px solid darken($primary-color, 20%);
    }
  }
  
  .menu {
    li:last-child {
      border-right: 1px solid darken($primary-color, 20%);
    }
  }

}

// Side Menu

 .subnav li:first-child {
    border-top: 1px solid darken($primary-color, 20%);
  }

  .subnav li {
    border-bottom:1px solid darken($primary-color, 20%);
    margin-right:17px;
  }

// Small Menu

 .menu-borders li:first-child {
    border-top: 1px solid darken($primary-color, 20%);
  }

  .menu-borders li {
    border-bottom:1px solid darken($primary-color, 20%);
  }


// footer menu

#footer .menu > li > a{font-size: rem-calc(10);}  


/* Grey Block */

#logo {
	position:absolute; top:20px; left:20px; width:480px; height:142px;
	}

.greyblock{background-color:$blockcolor; height:228px; border-bottom-style:solid; border-bottom-width:3px; border-bottom-color: darken($blockcolor, 30%);}
.greyblock-sm{background-color:$blockcolor; height:128px; margin-bottom:10px;}


/* Banner */

#banner{
	padding-top:20px;
	.banner-back{
		color:white;
		background-color:$secondary-color;
		position:relative;
	}	
	.news-block{color:#FFF;}
	.same-height{
		min-height:302px;
		}
	a.banner-button {
		color:#FFF;
		background-color:$primary-color;
		border-radius: 25px 25px 25px 25px;
		bottom: 20px;
		height: 50px;
		opacity: 1;
		position: absolute;
		right: 20px;
		transition: opacity 0.2s ease-in-out 0s, display 0.2s ease-in-out 0s;
		width: 50px;
		font-family:"Palatino Linotype", "Book Antiqua", Palatino, serif;
		font-style:italic;
		padding-left: 7px;
		padding-top: 17px;
		display:block;
	}
	a.banner-button:hover{opacity:0.5;}
	.banner-header{
		height:57px; 
		background-color: darken($secondary-color , 25%);
		padding:5px 0 0 20px;
		margin-top:20px;
		}
	.banner-header h4{color:$secondary-color; font-size:24px; font-weight:normal;}
	.banner-content{
		font-size: 12px;
		left: 10px;
		padding: 20px;
		position: absolute;
		top: 80px;
		h3{ color:#FFF;}
		ul li{
		font-size:16px;
		list-style-type: none;
		background: url('../img/layout/tick.png') no-repeat top left;
		padding-left:40px;
		line-height: 30px;
		} 
		}
	}
/* Content */	
#content-1{padding:0 1.875em 0 1.875em;}
#content-2{}	

.vcard {
	margin:5px 0 0 0;
    border: none;
	li{color:$primary-color;}
	li.email img{margin-bottom:-3px;}
	a{ color:$primary-color;}
	h3{padding:0;margin:0;}
}

li.fn h3 {margin-top:10px;}

li.email a{padding:0; margin:0 0 5px 0;}

/* Footer */

#footer {
	background: $blockcolor;
	padding: 25px 0;
	 h5 {
		color: #666666;
		line-height: 24px;
		margin-bottom: 0;
		margin-top: 10px;
	}
	p {
	clear: both;
	color: #666666;
	padding-top: 2px;
	}
	a {
    color: #FFFFFF;
	}
	.inline-list a{text-indent:-1000px}
}


#copyright {
    background: none repeat scroll 0 0 #3F3F3F;
    color: #FFFFFF;
    padding: 11px 0;
		p, ul {
		margin-bottom: 0;
		font-size:0.750em;
		line-height:1.875em;

	}
	a {
    color: #FFFFFF;
    font-size:rem-calc(13);
    }
    p{padding: rem-calc( 7 10 20 0);}
}

/* Tabs & Table */

.rowprice{border-left:1px dashed #CCC; border-right:1px dashed #CCC; background-color: lighten($secondary-color, 50%);}

.content table {font-size:xx-small;}

/* Smartphones (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {
	.content{padding:0 !important;}
	.content table {font-size:small;}
	.orbit-container .orbit-slides-container img{max-width:none !important;}
}

@media only screen and (min-width: 768px) {
	.content{padding:0 !important;}
	.content table {font-size: 0.875em; width:100%;border: none!important;margin-bottom:0!important;}
	}

#google_translate_element *{
	-moz-box-sizing: content-box; 
	-webkit-box-sizing: content-box; 
	box-sizing: content-box;
	}
.inline-list > li > img{display:inline;}

.rowprice-last {
  border-left: 1px dashed #CCC;
  background-color: #fcfbef;
}
.book-price table{font-size:12px;}
.book-price table td{padding: 0.3em 0.3em;}

// Title Bar for Small

.small-bar{
	height:rem-calc(60);
	padding-top: 20px;
	color:$white;
	font-size:rem-calc(16);
	line-height:18px;
  .menu-icon {
    @include hamburger($color: white, $color-hover: $medium-gray);
  }

  .menu-icon.dark {
    @include hamburger;
  }
}






